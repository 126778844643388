<template>
  <div>
    <material-card
      color="general"
      :title="$t('Timeslot.title')"
      :text="$t('Timeslot.subtitle')"
    >
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
        <template #activator="{ on }">
          <v-btn
            color="zwonogreen"
            dark
            class="mb-2 z-btn-top-right"
            v-on="on"
          >
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t('Common.new_item') }}
          </v-btn>
        </template>

        <v-card>
          <v-card-title class="text-h5 z-card-title">
            <v-icon left>
              mdi-account-clock-outline
            </v-icon>
            {{ $t('Timeslot.subtitle') }}
            <v-spacer />
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form 
              ref="editTimeslotsForm"
              v-model="editedItem.isValid"
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex>
                    <!-- <v-text-field
                    type="number"
                    v-model="editedItem.start_hour"
                    label="Start Hour"
                  />
                  <v-text-field
                    type="number"
                    v-model="editedItem.end_hour"
                    label="End Hour"
                  /> -->
                    <!-- Start time -->
                    <v-menu
                      ref="menu"
                      v-model="menu_start"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.start"
                          :label="$t('Timeslot.start_time')"
                          prepend-icon="access_time"
                          readonly
                          v-bind="attrs"
                          :rules="[rules.required]"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="menu_start"
                        v-model="editedItem.start"
                        full-width
                        format="24hr"
                        :max="editedItem.end"
                        @click:minute="$refs.menu.save(time)"
                      />
                    </v-menu>
                    <!-- End time -->
                    <v-menu
                      ref="menu"
                      v-model="menu_end"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template #activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.end"
                          :label="$t('Timeslot.end_time')"
                          prepend-icon="access_time"
                          readonly
                          v-bind="attrs"
                          :rules="[rules.required]"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="menu_end"
                        v-model="editedItem.end"
                        full-width
                        format="24hr"
                        :min="editedItem.start"
                        @click:minute="$refs.menu.save(time)"
                      />
                    </v-menu>
                  <!-- <h4>Start time</h4>
                  <v-time-picker v-model="editedItem.start" format="24hr" :max="editedItem.end"></v-time-picker>
                  <h4>End time</h4>
                  <v-time-picker v-model="editedItem.end" format="24hr" :min="editedItem.start"></v-time-picker> -->
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
            <v-alert
              v-if="alertTextEdit"
              dense
              type="error"
            >
              {{ alertTextEdit }}
            </v-alert>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              color="zwonogray"
              class="z-hover-red-bg-color pr-5"
              @click="close"
            >
              <v-icon left>
                mdi-cancel
              </v-icon>
              {{ $t('Common.cancel') }}
            </v-btn>
            <v-btn
              color="zwonogreen"
              class="pr-5"
              :loading="isSaving"
              :disabled="!editedItem.isValid"
              @click="save"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ $t('Common.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <v-data-table
          :loading-text="$t('Common.loading-text')"
          :headers="headers"
          :items="timeslotList ? timeslotList : []"
          :options.sync="paginationOptions"
          :server-items-length="(timeslotListMeta?.itemCount) ? timeslotListMeta.itemCount : 0"
          :loading="paginationLoading"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50]
          }"
          class="elevation-1"
        >
          <!-- change table header color(or other properties) -->
          <template
            slot="headerCell"
            slot-scope="{ header }"
          >
            <span
              class="subheading font-weight-light text-general text--darken-3"
              v-text="header.text"
            />
          </template>
          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-green-color mr-2"
                  v-bind="attrs"
                  @click="editItem(item)"
                  v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('Common.tooltips.edit') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  medium
                  class="z-hover-red-color"
                  v-bind="attrs"
                  @click="showDeleteModal(item)"
                  v-on="on"
                >
                  delete
                </v-icon>
              </template>
              <span>{{ $t('Common.tooltips.delete') }}</span>
            </v-tooltip>
          </template>
          <template #no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Reset
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </material-card>
    <default-popup
      :title="$t('Common.warning')"
      :text="$t('Common.delete_confirmation')"
      :confirm-text="$t('Common.delete_item')"
      :cancel-text="$t('Common.cancel')"
      :alert-text="alertTextDelete"
      :is-visible="isDeleteModalVisible"
      @is-visible="value => isDeleteModalVisible = value"
      @confirm-btn-click="() => deleteItem()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultPopup from '../../components/DefaultPopup.vue';

export default {
  components: {
    DefaultPopup,
  },
  data: () => ({
    dialog: false,
    isDeleteModalVisible: false,
    deleteModalItemId: 0,
    editedIndex: -1,
    editedItem: {
      isValid: true,
      start: null,
      end: null
    },
    defaultItem: {
      isValid: true,
      start: null,
      end: null
    },
    time: null,
    menu_start: false,
    menu_end: false,
    alertTextEdit: '',
    alertTextDelete: '',
    isSaving: false,
    // table:
    paginationCount: 0,
    paginationLoading: false,
    paginationOptions: {},
  }),

  computed: {
    ...mapGetters('timeslot', ['timeslotList', 'timeslotListMeta']),

    headers() {
      return [
        { text: this.$t('Timeslot.start_time'), value: 'start' },
        { text: this.$t('Timeslot.end_time'), value: 'end' },
        { text: this.$t('Common.actions'), value: 'actions',  sortable: false }
      ]
    },
    rules() {
      return {
        required: value => !!value || this.$t('Common.validation_required'),
      }
    }
  },

  watch: {
    dialog(val) {
      this.alertTextEdit = ''
      if (val) this.$nextTick(() => {this.$refs.editTimeslotsForm.validate()});
      else this.close();
    },
    paginationOptions: {
      handler() {
        this.initialize();
      },
      deep: true, // Watch changes deeply in the object
    },
  },

  // lifecycle hooks
  beforeDestroy() {
    this.clearTimeslotState();
  },

  methods: {
    ...mapActions('timeslot', [
      'getTimeslotList',
      'createTimeslot',
      'updateTimeslot',
      'deleteTimeslot',
      'clearTimeslotState',
    ]),
    async initialize() {
      this.paginationLoading = true
      this.paginationOptions.role = sessionStorage.getItem("role");
      await this.getTimeslotList(this.paginationOptions);
      this.paginationLoading = false
    },

    editItem(item) {
      this.editedIndex = this.timeslotList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    showDeleteModal(item) {
      this.alertTextDelete = ''
      this.isDeleteModalVisible = true
      this.deleteModalItemId = item?.id
    },

    async deleteItem() {
      if (this.deleteModalItemId) {
        const isDeleted = await this.deleteTimeslot(this.deleteModalItemId)
        if (isDeleted) {
          this.isDeleteModalVisible = false
        } else {
          this.alertTextDelete = this.$t('Timeslot.alerts.error-delete')
        }
      }
    },

    close() {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },

    async save() {
      this.isSaving = true
      this.alertTextEdit = ''
      if (this.editedIndex > -1) {
        const isUpdated = await this.updateTimeslot(this.editedItem);
        if (isUpdated) {
          this.close()
        } else {
          this.alertTextEdit = this.$t('Timeslot.alerts.error-edit')
        }
      } else {
        const isCreated = await this.createTimeslot(this.editedItem);
        if (isCreated) {
          this.close()
        } else {
          this.alertTextEdit = this.$t('Timeslot.alerts.error-create')
        }
      }
      this.isSaving = false
    }
  },
}
</script>
